<template>
  <div class="SelectSchool">
    <a-tree-select
      v-show="centerType == 'center'"
      :style="{ width: width }"
      :treeData="treeData"
      :value="value"
      @change="onChange"
      treeCheckable
      searchPlaceholder="请选择中心"
    />
    <a-tree-select
      v-show="centerType != 'center'"
      :style="{ width: width }"
      :treeData="treeData"
      :value="value"
      @change="onChange"
      treeCheckable
      :showCheckedStrategy="SHOW_PARENT"
      searchPlaceholder="请选择中心"
    />
  </div>
</template>
<script>
// template里面
// <sSchool v-bind="centerObj" @getCenterData="getCenterData"></sSchool>
// script 里面
//import sSchool from '../../components/SelectSchool/SelectSchool';//选中心
// export default{ components:{sSchool}, }
// data => return{ centerObj:{centerType: 'center',width: '400px'},}// 默认选的中心 width:300px;=={centerType: 'center',width: '300px'}
//methods:getCenterData(val){console.log(val)}

import { TreeSelect } from 'ant-design-vue'
const SHOW_PARENT = TreeSelect.SHOW_PARENT

export default {
  name: '',
  // props: ['centerType','width'],
  props: {
    centerType: {
      type: String,
      default: 'center'
    },
    value: {
      type: Array,
      default: []
    },
    width: {
      type: String,
      default: '300px'
    },
    treeData: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      treeData: this.treeData,
      // value: [],
      SHOW_PARENT
    }
  },

  methods: {
    onChange (value) {
      console.log('onChange ', value, arguments)
      this.value = value
      console.log(this.value)
      this.$emit('getCenterData', this.value, this.center)
    }
  }
}
</script>
<style lang="less">
// .SelectSchool .ant-select-selection--multiple .ant-select-selection__choice {
//     // border: 0px solid transparent;
//     // background-color: transparent;
// }
.ant-select-dropdown--multiple {
  max-height: 460px !important;
}
.SelectSchool .ant-select-selection__rendered {
  white-space: nowrap;
}
.SelectSchool .ant-select-selection--multiple .ant-select-selection__choice {
  margin-right: 2px;
  padding-left: 4px;
  display: inline-block;
}
</style>
