<template>
    <div class="tree-box">
        <!-- <div v-for="(item, index) of treeData" :key="index">
            <div @click="selectFn(item)" :class="{'tree-li':true,'border-r':id==item.id,'selected':id==item.id,'border-r-rgb':id!=item.id}" :style="{'text-indent':item.children?'0px':'24px'}"> <a-icon :type="arSel.indexOf(item.id)>0?'caret-down':'caret-right'" @click.stop="openclick(item)" v-if="item.children" style="margin-right: 6px;"/>{{item.title}}</div>
            <div v-if="item.children&&arSel.indexOf(item.id)>0" v-for="(list,k) in item.children" :key="k" @click="selectFn(list)" :class="{'tree-li':true,'border-r':id==list.id,'selected':id==list.id,'border-r-rgb':id!=list.id}" style="text-indent: 40px;">{{list.title}}</div>
            <tree-list v-if="item.children&&arSel.indexOf(item.id)>0" :list="item.children" v-bind="$attrs" v-on="$listeners"></tree-list>
        </div> -->

        <div v-for="(item, index) of list" :key="index" >
            <div @click="selectFn(item)" :class="{'tree-li':true,'border-r':id==item.id,'selected':id==item.id,'border-r-rgb':id!=item.id}" :style="{'text-indent':item.children?'':46+'px'}" > 
              <a-icon :type="ar.indexOf(item.id)>0?'caret-down':'caret-right'" @click.stop="openclick(item)" v-if="item.children" style="margin-right: 4px"/>
              {{item.title}}
            </div>
            <div v-show="(ar.indexOf(item.id)>-1)" style="text-indent:12px">
                <tree-list v-if="item.children&&ar" :list="item.children" v-bind="$attrs" v-on="$listeners"></tree-list>
            </div>
        </div> 
      
    </div>
</template>
 
<script>
export default {
  name:'TreeList',
  props:{
    list:{
      type: Array,
      default:function(){
        return [
          {title:'1级',id:1111,children:[
            {title:'1.1级',id:11111},
            {title:'1.2级',id:11112},
            {title:'1.3级',id:11113}
          ]},
          {title:'2级',id:2222,children:[
            {title:'2.1级',id:22221,children:[
              {title:'2.1.1级',id:222211},
              {title:'2.1.3级',id:222213},
            ]},
            {title:'2.2级',id:3333},
            {title:'2.3级',id:4444}
          ]}
        ]
      },
     
    }

  },
  data(){
    return {
       id: '',//选中的部门Id
       ar:[9999,],//选中的部门
      // arSel: [9999,],//选中的部门
      // id: '',//选中的部门Id
    }
  },
  methods:{
    // openclick(obj){//打开与收起
    //     let num;
    //     if(this.arSel.length>0){
    //         for(let i=0;i<this.arSel.length;i++){ 
    //         console.log(obj.id,this.arSel[i])
    //         if(obj.id==this.arSel[i]){
    //             num = i
    //         }
    //         }
    //         console.log(num)
    //         num? this.arSel.splice(num,1):this.arSel.push(obj.id);
    //         console.log(this.arSel);
    //     }else{
    //         this.arSel.push(obj.id);
    //     }     
    //     console.log(this.arSel)
    // },
    //选中
      selectFn(obj){
          console.log(obj);
          console.log(this.id)
          this.id = '';
          this.id=obj.id;
      },
      openclick(obj){
        let num;
        if(this.ar.length>0){
            for(let i=0;i<this.ar.length;i++){ 
              if(obj.id==this.ar[i]){
                  num = i
              }
            }
            num? this.ar.splice(num,1):this.ar.push(obj.id);
        }else{
            this.ar.push(obj.id);
        } 
        console.log(this.ar)
      }
  },
}
</script>
<style lang="less" scoped>
.tree-box{
  width: 390px;
  overflow-x: hidden;
  font-size:14px;


}
.item{
  margin-left:10px;
}
 .fade-enter-active, .fade-leave-active {
        transition:  1s;
    }
</style>
