<template>
        <div class="input-serach" style="width: 40%;position: relative;z-index: 10;" @click.stop="inp()">
            <a-input-search placeholder="input search text" v-model="serachVal" @search="onSearch" >
                <a-button class="ant-btn-primary"  slot="enterButton">搜索</a-button>
            </a-input-search>
            <div class="input-select" v-show="bool&&dataList.length>0">
                <div class="list flexBox" v-for="(item,i) in dataList" :key="i" v-show="i<numShow" @click.stop="seleced(item)"><span>{{item}}</span> <span @click.stop="del(i)" >删除&nbsp;&nbsp;</span></div>
                <div class=" ant-menu-item-selected " v-show="numShow!=dataList.length" @click.stop="showFn()" style="text-align: center;font-size: 14px;height:30px;line-height:30px;cursor: pointer;">加载更多</div>
            </div>
        </div>
</template>

<script>
    //<serachInput @backSerachVal="backSerachVal" v-bind="serachObj" ></serachInput>
    //传参数
    // serachObj:{
    //     localName: 'no-localStorage',//local_cx 自定义的名字 存储，no-localStorage不存储
    // }
    export default {
        name: "input-serach",
        props: {
            localName: {
                type: String,
                require: true
            },
        },
        data(){
            return{
                serachVal: '',
                bool: false,
                dataList: [],
                numShow: 6,//默认显示6条，点击更多时
            }
        },
        created(){
            // console.log(this.localName)
            //判断是否需要存储
            if(this.localName=='no-localStorage')return;
            let localInfo = JSON.parse(localStorage.getItem(this.localName));
            //判断存储里面有内容没有
             if (localInfo){
                 this.dataList = localInfo;
             }
        },
        methods:{
            backSerachVal(){
                window.console.log(this.serachVal)
            },
            onSearch(){
                this.bool = false;
                if(!this.serachVal)return;
                this.$emit('backSerachVal',this.serachVal);
                //如果不存储就return;
                if(this.localName=='no-localStorage')return;
                //判断存储里面是否有数据，如果输入的localStorang
                if(this.dataList.length>0){
                    for(let i=0;i<this.dataList.length;i++){
                        if(this.dataList[i]==this.serachVal){
                            this.dataList.splice(i,1)
                        }
                    }
                }
                this.dataList.push(this.serachVal);
                localStorage.setItem(this.localName,JSON.stringify(this.dataList))
            },
            inp(){
                this.bool=!this.bool;
            },
            seleced(value){
                this.bool=false;
                this.$emit('backSerachVal',value);
                this.serachVal = [];
                this.serachVal = value;
            },
            del(i){
                this.dataList.splice(i,1);
                localStorage.setItem(this.localName,JSON.stringify(this.dataList))
            },
            //显示更多
            showFn(){
                this.numShow = this.dataList.length;
            }

        }
    }

</script>
<style scoped lang="less">
    .flexBox{
        display: flex;
        justify-content: space-between;
    }
    .input-select{
        position: absolute;
        top: 36px;
        left: 0px;
        border-radius: 6px;
        height: 260px;
        overflow: hidden;
        overflow-y: auto;
        border:1px solid blue;
        width: calc(100% );
        padding: 6px 0px;

        .list{
            text-indent: 10px;
            background: #fff;
            height:36px;
            line-height: 36px;
            cursor: pointer;
            color: #666666;
        }
        .list:hover{
             background: #e8e8e8;
             color: #999999;
        }
    }

</style>
