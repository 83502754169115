<template>
    <div class="SingularFile">
        <a-upload
                name="avatar"
                listType="picture-card"
                class="avatar-uploader"
                :showUploadList="false"
                action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                @change="handleChange"
                :beforeUpload="beforeUpload"
        >
            <img v-if="imageUrl" :src="imageUrl" class="img" alt="avatar" />
            <div v-else style="width: 100%;height: 100%">
                <a-icon :type="loading ? 'loading' : 'plus'" />
                <div class="ant-upload-text">Upload</div>
            </div>
        </a-upload>
    </div>
</template>

<script>
    export default {
        name: "SingularFile",
        data(){
            return{
                imageUrl: '',
                loading: false
            }
        },
        methods: {
            handleChange(info) {
                let self = this;
                if (info.file.status === 'uploading') {
                    this.loading = true;
                    // this.__uploadFile__(info.fileList).then((res)=>{
                    //     console.log(res);
                    // })
                }
                if (info.file.status === 'done') {
                    this.getBase64(info.file.originFileObj, imageUrl => {
                        self.imageUrl = imageUrl;
                        this.loading = false;
                    });
                }
            },
            beforeUpload(file) {
                const isJPG = file.type === 'image/jpeg';
                if (!isJPG) {
                    this.$message.error('You can only upload JPG file!');
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    this.$message.error('Image must smaller than 2MB!');
                }
                return isJPG && isLt2M;
            },
            getBase64(img, callback) {
                const reader = new FileReader();
                reader.addEventListener('load', () => callback(reader.result));
                reader.readAsDataURL(img);
            }
        },
        props:{

        }
    }
</script>

<style lang="less">
    .SingularFile{
        width: 100%;
        height: 100%;
        img{
            width: 100%;
            height: 100%;
        }

    }
    .ant-upload{
        width: 100% !important;
        height: 100% !important;
    }
</style>