<template>
  <div class="test-1">
    <div>测试1</div>
    <a-divider>日期 测试语言切换</a-divider>
    <a-range-picker @change="onChange" />
    {{ $t('message') }} 122
    {{ $i18n.message }}
    <br />
    {{ $i18n.message.setting }}
    <a-divider>files tree</a-divider>
    <!-- 
           treeNodeLabelProp= 'title'   treeCheckable -->
    <!-- <a-tree-select
            style="width: 300px"
            :treeData="posts_type_arr"
            :value='value'
            @change="onChangetree"
            :showCheckedStrategy="SHOW_PARENT"
            searchPlaceholder="Please select"
            :dropdownStyle="{ display:'block !important',maxHeight: '400px', overflow: 'auto' }"
          /> -->
    <a-tree-select
      :style="{ width: '300px' }"
      :treeData="posts_type_arr"
      :value="value"
      @change="onChangetree"
      treeCheckable
      :showCheckedStrategy="SHOW_PARENT"
      searchPlaceholder="请选择中心"
    />
    <a-divider>files tree end</a-divider>
    <a-divider>vue 选职位</a-divider>
    <a-tree
      checkable
      :treeData="posts_type_arr"
      @select="this.onSelectPosts"
      @check="this.onCheck"
    />

    <div><input type="text" /></div>
    <router-link to="/test-3">李泳章</router-link>
    <a-button type="primary" @click="jsFn">js(cx)</a-button>
    <a-popconfirm title="Are you sure？" okText="Yes" cancelText="No">
      <a href="#">Delete</a>
    </a-popconfirm>
    <div>cx <i class="iconfont icon-tianjia ant-menu-item-selected"></i></div>
    <!-- <span v-html="$t('message.LAN_LOGIN')"></span> -->
    选人：<!-- :rank="1" 职级  :nums="1"选人数  department='0'//选部门-->
    <!-- {{listCX}} -->
    <person v-bind="personObj" @getData="getData"></person>
    <person v-bind="personObj2" @getData="getData2"></person>
    <a-divider>vue 递归组件 自定义选人</a-divider>
    <div>
      <span v-for="(item, l) in listCX" :key="l"
        >{{ item.label }}{{ item.title }}-{{ l + 1 }}</span
      >
    </div>
    <trlist :list="rout"></trlist>
    <a-divider>vue 递归组件 end 没加过度动画</a-divider>
    <a-divider>文件柜 树时时加载</a-divider>
    <a-tree :loadData="onLoadData2" showIcon :treeData="treeData2">
      <a-icon
        slot="smile"
        type="folder"
        style="margin-left: -12px"
        theme="filled"
      />
      <a-icon
        slot="meh"
        type="folder"
        style="margin-left: -12px"
        theme="filled"
      />
      <template slot="custom" class="border">
        <!-- <a-icon :type="selected ? 'frown':'frown-o'" /> -->
        <a-icon slot="meh" type="folder" style="margin-left: -12px" />
      </template>
    </a-tree>
    <a-divider>文件柜 end </a-divider>
    <serachInput
      @backSerachVal="backSerachVal"
      v-bind="serachObj"
    ></serachInput>
    <a-divider>选择中心</a-divider>
    <!-- <sSchool v-bind="centerObj" @getCenterData="getCenterData"></sSchool>   -->
    <div class="FilesBox">
      <a-checkbox value="A">A145456132</a-checkbox>
      <label
        ><li>gdshs5g454<input type="checkbox" value="cx" /></li
      ></label>
    </div>
    <a-divider />
    <a-divider>2层</a-divider>
    <div
      v-for="(item, index) of list2ceng"
      :key="index"
      class="item border-top"
    >
      <div
        @click="selectFn(item)"
        :class="{
          'border-r': id == item.id,
          selected: id == item.id,
          'border-r-rgb': id != item.id
        }"
        :style="{ 'text-indent': item.children ? '0px' : '24px' }"
      >
        <a-icon
          :type="ar.indexOf(item.id) > 0 ? 'caret-down' : 'caret-right'"
          @click.stop="openclick(item)"
          v-if="item.children"
          style="margin-right: 6px;"
        />{{ item.title }}
      </div>
      <div
        v-if="item.children && ar.indexOf(item.id) > 0"
        v-for="(list, k) in item.children"
        :key="k"
        @click="selectFn(list)"
        :class="{
          'border-r': id == list.id,
          selected: id == list.id,
          'border-r-rgb': id != list.id
        }"
        style="text-indent: 30px;"
      >
        {{ list.title }}
      </div>
    </div>
    <a-divider>end</a-divider>
    <div id="ImageContainer"></div>

    <audio
      src="http://sk-ims-cabinet.sikegroup.com/m7T7ZZ4b_11.mp3"
      controls="controls"
    >
      Your browser does not support the audio element.
    </audio>
    <audio src="http://sk-ims-cabinet.sikegroup.com/m7T7ZZ4b_11.mp3"></audio>
    <a-divider>单文件上传</a-divider>
    <!--<a-upload-->
    <!--listType="picture-card"-->
    <!--&gt;-->
    <!--<div >-->
    <!--<a-icon type="plus" />-->
    <!--<div class="ant-upload-text">Upload</div>-->
    <!--</div>-->
    <!--</a-upload>-->

    <!--<div class="cx">-->
    <!--<SingularUpLoad style="width: 100%;height:100%"></SingularUpLoad>-->
    <!--</div>-->
    <div style="width: 80px;height: 80px;">
      <input
        type="file"
        @change="_uploadFile"
        class="uploadFile"
        multiple="multiple"
      />
    </div>

    <a-cascader
      :options="options"
      @change="onChange_cascader"
      placeholder="Please select"
    />
    <a-table
      :columns="columns"
      :dataSource="data"
      :pagination="false"
    ></a-table>
    <video
      id="Video"
      src="https://www.runoob.com/try/demo_source/movie.mp4"
    ></video>
  </div>
</template>

<script>
//   var PSD = require('psd');
//   // import PSD from 'psd';//选人
//  //从URL加载
//  PSD.fromURL("@/assets/images/abc.psd").then(function(psd) {
//    console.log(psd.image.toPng())
//   //  document.getElementById('ImageContainer').appendChild(psd.image.toPng());
//  });
//  function onDrop(evt) {
//   PSD.fromEvent(evt).then(function (psd) {
//     console.log(psd.tree().export());
//   });
// }
const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
    width: '12%'
  },
  {
    title: 'Address',
    dataIndex: 'address',
    width: '30%',
    key: 'address'
  }
]

const data = [
  {
    name: 'John Brown sr.',
    age: 60,
    address: 'New York No. 1 Lake Park',
    children: [
      {
        name: 'John Brown',
        age: 42,
        address: 'New York No. 2 Lake Park'
      },
      {
        name: 'John Brown jr.',
        age: 30,
        address: 'New York No. 3 Lake Park',
        children: [
          {
            name: 'Jimmy Brown',
            age: 16,
            address: 'New York No. 3 Lake Park'
          }
        ]
      },
      {
        name: 'Jim Green sr.',
        age: 72,
        address: 'London No. 1 Lake Park',
        children: [
          {
            name: 'Jim Green',
            age: 42,
            address: 'London No. 2 Lake Park',
            children: [
              {
                name: 'Jim Green jr.',
                age: 25,
                address: 'London No. 3 Lake Park'
              },
              {
                name: 'Jimmy Green sr.',
                age: 18,
                address: 'London No. 4 Lake Park'
              }
            ]
          }
        ]
      }
    ]
  },
  {
    name: 'Joe Black',
    age: 32,
    address: 'Sidney No. 1 Lake Park'
  }
]

const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      'selectedRows: ',
      selectedRows
    )
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows)
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows)
  }
}

const treeData4 = [
  {
    value: '9d78a4c3b7cbfc23ece7e3a1bfc84b3b',
    title: '基层',

    children: [
      {
        value: '0c469b9416611d3a8920b3f151ef08c3',
        title: '兼职',
        pid: 12,
        children: [
          {
            value: '18f2e8fcd68882d3e46af18027a71494',
            title: '兼职',
            pid: 156
          }
        ]
      },
      {
        value: '6d4757dbe684edd474a76fadca194dd4',
        title: '员工',
        pid: 158
        // children:[
        //     {
        //         value:"12748be149b567cbdb9b9b58ba883f6e",
        //         title:"班主任",

        //     },
        //     {
        //         value:"12b7a23bd789d593070c07880784be41",
        //         title:"出纳",
        //     },
        //     {
        //         value:"1a7fa41a198438ee70fc3ce812bc169c",
        //         title:"产品经理",

        //     },
        //     {
        //         value:"31e215079d2980773e6e96aea2416368",
        //         title:"老师",

        //     },
        //     {
        //         value:"4450fa7b76aa020d20db8c65ef88f460",
        //         title:"HR专员",

        //     },
        //     {
        //         value:"50b9300b89e0e58088d52ea13057e648",
        //         title:"客服",

        //     },
        //     {
        //         value:"5243e8a8572b38f6a629b104ca4ffe2e",
        //         title:"顾问",

        //     },

        //     {
        //         value:"894e9d1838d7e7e6bb8d0ba9909a9097",
        //         title:"会计",

        //     },
        //     {
        //         value:"8bbcd78adb46ff8bbf62e9a5bb5a3d7b",
        //         title:"市场专员",

        //     },
        //     {
        //         value:"8f1250bcbb80cb5d4e72e93e5453367f",
        //         title:"排课老师",

        //     },
        //     {
        //         value:"bfed9d9d20ce6f84003cf7d151760f9e",
        //         title:"新媒体运营",

        //     },
        //     {
        //         value:"fbb6f9378b78aa59b84f1bcfba09b2aa",
        //         title:"工程师",

        //     }
        // ]
      }
    ]
  },
  {
    value: 'a5bc9521fa5b01663c7e95b71496a252',
    title: '管培生',

    children: [
      {
        value: 'd4db1499c8d095d850cc495fe5c92500',
        title: '管培生'
      }
    ]
  }
]
import trlist from '../../components/TreeList/Menu_tree'
import person from '../../components/SelectPersonnel/SelectPersonnel' //选人
import sSchool from '../../components/SelectSchool/SelectSchool' //选中心
import serachInput from '../../components/SelectPersonnel/inputSerach'
import { TreeSelect } from 'ant-design-vue'
import SingularUpLoad from 'components/UpLoad/SingularFile'
var arr_label = [] //存过滤的节点

const SHOW_PARENT = TreeSelect.SHOW_PARENT
export default {
  name: 'test-1',
  components: { trlist, person, serachInput, sSchool, SingularUpLoad },
  data () {
    return {
      data,
      columns,
      rowSelection,
      // expandedKeys: [],
      // checkedKeys: [],
      // selectedKeys: [],

      listCX: [],
      treeData4,
      SHOW_PARENT,
      posts_type_arr: [],
      value: ['6d4757dbe684edd474a76fadca194dd4'],
      //2层
      id: '',
      ar: [9999],
      list2ceng: [
        {
          title: '1级',
          id: '111',
          children: [
            { title: '1.1级', id: '123' },
            { title: '1.2级', id: '124' },
            { title: '1.3级', id: '125' }
          ]
        },
        {
          title: '2级',
          id: '222',
          children: [
            { title: '2.1级', id: '223' },
            { title: '2.2级', id: '224' },
            { title: '2.3级', id: '225' }
          ]
        },
        { title: '2.2级', id: 333 },
        { title: '2.3级', id: 444 }
      ],

      centerObj: { centerType: 'center', width: '400px', value: [] },
      //  trre,
      images: [
        'http://wework.qpic.cn/wwhead/duc2TvpEgST9hicuyypLEKNrJkSGEXAW2dWyVMG9BUDpGr7tIb0Lmmy8vv54vmvBIpzKTMFMB8MQ/0',
        'http://wework.qpic.cn/wwhead/duc2TvpEgST9hicuyypLEKDKroCZws6D2Ef6KdDZjxmsAc53YUdlvpByKFB4a9lpg129uzfKF2yw/0'
      ],
      personObj: {
        role: 2, //1为单选 //2多选//0为不要选角色
        rank: 2, //职级 0为不选， //2多选 其他为选
        nums: 10, //选的数量
        positions: 2, //职位 0为不选，其他为最顶层 //2选最底层
        department: 5, //0不选部门，1为都选（人 部门都选），2为只选部门，不选人 3为不要部门和人 4中心最顶层 5最底层
        contacts: 'recent11', //常用联系人recent 不要就传其他
        selectArr: [] //要传入的数据//数据中type（中心部门1 职级2，角色3，职位4）
      },
      personObj2: {
        role: 2, //1为单选 //2多选//0为不要选角色
        rank: 0, //职级 0为不选， //2多选 其他为选
        nums: 100, //选的数量
        department: 1, //0不选部门，1为都选（人 部门都选），2为只选部门，选人
        positions: 2, //职位 0为不选，其他为最顶层 //2选最底层
        contacts: 'recent', //常用联系人recent 不要就传其他
        selectArr: [] //要传入的数据////数据中type（中心部门1 职级2，角色3，职位4）
      },
      serachObj: {
        localName: 'no-localStorage' //local_cx自定义存储名字，no-localStorage不存储
      },

      list: [],
      fileMeList: [
        {
          title: '小王子事业部',
          value: '9218d625d08eda38b1af0d375d18db2e',
          key: '0'
        },
        {
          title: '思可总部',
          value: '4ae815d14502f24601453b40a7ff1c21',
          key: '0'
        },
        {
          title: '思可在线',
          value: 'c388feb6274d3212e54bebcd69dc58e8',
          key: '0'
        }
      ],

      rout: [
        {
          title: '1-1级',
          id: 123456,
          children: [
            { title: '1-1.1级', id: 12345678 },
            { title: '1-1.2级', id: 12345679 },
            { title: '1-1.3级', id: 12345680 }
          ]
        },
        {
          title: '2级',
          id: 1234567,
          children: [
            {
              title: '陈星2.1级',
              id: 1234568,
              children: [
                { title: '2.1-1.1级', id: 12345689 },
                { title: '2.1-1.2级', id: 12345681 },
                { title: '2.1-1.3级', id: 12345682 }
              ]
            },
            { title: '2-2.2级', id: 12345691 },
            { title: '2.3级', id: 12345693 }
          ]
        }
      ],

      treeData2: [
        {
          title: 'Expand to load',
          key: '0',
          slots: {
            icon: 'smile'
          }
        },
        {
          title: 'Expand to load',
          key: '1',
          slots: {
            icon: 'smile'
          }
        },
        {
          title: 'Tree Node',
          key: '2',
          isLeaf: true,
          slots: {
            icon: 'smile'
          }
        }
      ],
      options: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          pid: 222777,
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              pid: 222666,
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                  pid: 222555
                }
              ]
            }
          ]
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          pid: 333,
          children: [
            {
              value: 'nanjing',
              label: 'Nanjing',
              pid: 222,
              children: [
                {
                  value: 'zhonghuamen',
                  label: 'Zhong Hua Men',
                  pid: 1111
                }
              ]
            }
          ]
        }
      ]
    }
  },

  created () {
    console.log(123)
    this.$axios.get(8007, {}, res => {
      console.log(123, res)
      if (res.data.code == 1) {
        this.posts_type_arr = res.data.data
      } else {
        this.$message.error('获取数据失败!')
      }
    })
    this.init()
  },
  mounted () {},
  watch: {
    checkedKeys (val) {
      console.log('onCheck', val)
    }
  },
  methods: {
    onChange_cascader (value) {
      console.log(arguments)
      console.log(value)
    },
    init () {
      let array = [
        '0-0-1',
        '0-0-1-0',
        '0-0-1-1',
        '0-0-1-2',
        '0-0-1-3',
        '0-0-1-4',
        '0-0-1-5',
        '0-0-1-6',
        '0-0-1-7',
        '0-0-1-8',
        '0-0-1-9',
        '0-0-1-10',
        '0-0-1-11',
        '0-0-1-12'
      ]
      let cookArr = []
      for (let i = 0; i < array.length; i++) {
        cookArr.push(array[i])
      }
      console.log(cookArr)
      for (let i = 0; i < array.length; i++) {
        for (let j = 0; j < array.length; j++) {
          // if(array[j].length>array[i].length){//字符长度判断，短的放在indexOf里面
          if (array[j].indexOf(array[i]) == 0) {
            if (array[i] != array[j]) {
              //  array.splice(j,1);
              console.log(cookArr[j])
              cookArr[j] = undefined
            }
          }
          // }else{
          //    if(array[i].indexOf(array[j])==0){
          //      if(array[i]!=array[j]){
          //       //  array.splice(j,1);
          //       //  cookArr[j]=undefined;
          //      }
          //    }
          //  }
        }
      }
      console.log(cookArr)
    },

    //岗位选择
    onSelectPosts (selectedKeys, info) {
      let id = info.selectedNodes[0].data.props.value
      console.log('selected', selectedKeys, id)
    },
    onCheck (checkedKeys, info) {
      let arr_info = [] //存选择的数据
      let back_arr = [] //最后返回页面的数据
      console.log(checkedKeys)
      console.log(info)
      for (let i = 0; i < checkedKeys.length; i++) {
        let obj = {} //将数据放在一起（包括label（keys） 如'0-1-1'） 到时候根据keys去拿要的数据
        obj.label = checkedKeys[i]
        obj.id = info.checkedNodes[i].data.props.value
        obj.name = info.checkedNodes[i].data.props.title
        arr_info.push(obj)
      }
      console.log(arr_info)
      //选的什么就是什么不做（只有最顶层）
      // for(let i=0;i<checkedKeys.length;i++){    //选最顶层
      //   for(let j=0;j<checkedKeys.length;j++){
      //       if(checkedKeys[j].indexOf(checkedKeys[i])==0){
      //         if(checkedKeys[i]!=checkedKeys[j]){
      //           //  checkedKeys.splice(j,1);
      //           // changeArr[j]=undefined;
      //           arr_info[j]= undefined;
      //         }
      //       }
      //   }
      // }
      // 只有最底层
      let checkedArr = info.checkedNodes
      for (let i = 0; i < checkedArr.length; i++) {
        if (checkedArr[i].data.props.dataRef.children.length > 0) {
          for (let k = 0; k < arr_info.length; k++) {
            console.log(arr_info[k])
            if (arr_info[k].id == checkedArr[i].data.props.dataRef.key) {
              arr_info[k] = undefined
            }
          }
        }
      }

      console.log(arr_info)
      for (let i = 0; i < arr_info.length; i++) {
        if (arr_info[i]) {
          back_arr.push(arr_info[i])
        }
      }
      console.log('end', back_arr)
    },

    _uploadFile () {
      let file = document.getElementsByClassName('uploadFile')[0].files
      this.__uploadFile__(file).then(res => {
        console.log(res)
      })
    },
    //2层
    onChangetree (value) {
      console.log(arguments)
      console.log('onChange ', value)
      this.value = value
    },
    openclick (obj) {
      let num
      if (this.ar.length > 0) {
        for (let i = 0; i < this.ar.length; i++) {
          console.log(obj.id, this.ar[i])
          if (obj.id == this.ar[i]) {
            num = i
          }
        }
        console.log(num)
        num ? this.ar.splice(num, 1) : this.ar.push(obj.id)
        console.log(this.ar)
      } else {
        this.ar.push(obj.id)
      }
      console.log(this.ar)
    },
    selectFn (obj) {
      console.log(obj)
      this.id = obj.id
    },

    onLoadData2 (treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve()
          return
        }
        setTimeout(() => {
          treeNode.dataRef.children = [
            {
              title: 'Child Node',
              key: `${treeNode.eventKey}-0`,
              slots: { icon: 'smile' }
            },
            {
              title: 'Child Node',
              key: `${treeNode.eventKey}-1`,
              slots: { icon: 'smile' }
            }
          ]
          this.treeData2 = [...this.treeData2]
          resolve()
        }, 1000)
      })
    },

    onLoadData (treeNode) {
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve()
          return
        }
        setTimeout(() => {
          treeNode.dataRef.children = [
            { title: 'Child Node', key: `${treeNode.eventKey}-0` },
            { title: 'Child Node', key: `${treeNode.eventKey}-1` }
          ]
          this.treeData = [...this.treeData]
          resolve()
        }, 1000)
      })
    },
    ajaxFn (treeNode) {
      console.log(treeNode)
      let user = JSON.parse(window.sessionStorage.getItem('userInfo'))
      return new Promise(resolve => {
        if (treeNode.dataRef.children) {
          resolve()
          return
        }

        this.$axios.post(
          9999,
          { UserId: user.uid, Pid: treeNode, IsPrivate: 0 },
          res => {
            if (treeNode == 0) {
              this.fileMeList = res.data.list
            } else {
              treeNode.dataRef.children = [
                { title: 'Child Node', key: `${treeNode.eventKey}-0` },
                { title: 'Child Node', key: `${treeNode.eventKey}-1` }
              ]
              this.treeData = [...this.treeData]
              resolve()
            }
          }
        )
      })
    },
    ajaxFn2 (meType, Id) {
      this.$axios.post(
        9999,
        { UserId: this.userInfo.uid, Pid: Id, IsPrivate: meType },
        res => {
          // console.log('cxcx',res)
          if (res.data.code == 0) {
            // console.log(this.fileMeList);
          } else if (res.data.code == 1) {
            if (meType != 1) {
              this.filePublicList = res.data.data
              // this.ajaxFn_list(0);
            } else {
              this.fileMeList = res.data.data
            }
          } else {
            this.$message.error('获取文件柜数据失败!')
          }
        }
      )
    },
    show () {
      const vuer = this.$el.querySelector('.images').$vuer
      vuer.show()
    },
    onChange (date, dateString) {
      // console.log(date, dateString);
    },
    jsFn () {
      // console.log(1)
      //  console.log(document.getElementsByTagName('html')[0].innerHTML);
    },
    //
    getData (val) {
      console.log(val, '选人')
      this.listCX = val
    },
    getData2 (val) {
      console.log(JSON.stringify(val))
      console.log(val, '选人2')
    },
    getCenterData (val) {
      console.log(val, '选中心')
    },
    backSerachVal (val) {
      // console.log(val,'搜索查询');
    },
    handleChange (value) {
      console.log(`selected ${value}`)
    },
    popupScroll () {
      console.log('popupScroll')
    }
  }
}
</script>

<style lang="less">
.FilesBox
  .ant-tree
  ul
  li
  .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(0, 0, 0, 0) !important;
}
.FilesBox .ant-tree li .ant-tree-node-content-wrapper.ant-tree-node-selected {
  background-color: rgba(0, 0, 0, 0) !important;
}
// .ant-upload.ant-upload-select-picture-card{
//   width: 50px !important;
//   height: 50px !important;
// }
</style>
